import React, {useState, useRef} from "react";
import 'moment/locale/it';
import 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {t} from "i18next";
import {Spinner, Row, Col, Container} from 'react-bootstrap';
import {strings_general_App, arr_error_code, EnumPrenotation} from "../../constants";
import {getUserClientId, saveUserClientId} from "../../LocalStorage/storage";
import { withRouter } from 'react-router-dom'
import UserClass from "../../LocalStorage/UserClass";
import DataManager from "../../DataManager";
import Functions from "../../Functions";

// Elements
import SeatMap from "./SeatMap/SeatMap";
import ButtonWizard from "./ButtonWizard";
import WidgetEvent from "../../elements/widget-event";
import unavailable from '../../../images/deselect.png';



const SelectMap = (props) => {

    const [err, setErr] = useState("");

    const [arrSeats, setArrSeats] = useState([]);
    const [arrLockSeats, setArrLockSeats] = useState([]);
    const [lockedSeat, setLockedSeat] = useState(null);
    const [unlockedSeat, setUnLockedSeat] = useState(null);
    const [checkSeat, setCheckSeat] = useState(false);
    const [maxNumTicket, setMaxNumTicket] = useState(0);

    const [items, setItems] = useState([]);
    const [loadItems, setLoadItems] = useState(false);

    const wsRef = useRef();

    React.useEffect(() => {
        let instance = UserClass.getInstance();
        let tokenUser = instance.getTokenUser();
        getItems(tokenUser, props.dataReply.id.toString())

        if (typeof (props.values.arrSeats) !== 'undefined' && props.values.arrSeats !== null && props.values.arrSeats.length > 0){
            setArrSeats(props.values.arrSeats);
            if (typeof (props.values.wsRef) !== 'undefined' && props.values.wsRef !== null){
                wsRef.current = props.values.wsRef.current;
            }
        }

        if(typeof (props.event) !== 'undefined' && props.event !== null){
            if(typeof (props.event.maximumBuyableSeatsPerOrder) !== 'undefined' && props.event.maximumBuyableSeatsPerOrder !== null){
                let num = props.event.maximumBuyableSeatsPerOrder.default;
                if(typeof (props.event.maximumBuyableSeatsPerOrder.bySalesChannel) !== 'undefined' && props.event.maximumBuyableSeatsPerOrder.bySalesChannel !== null){
                    const arrChannel = props.event.maximumBuyableSeatsPerOrder.bySalesChannel;
                    const arr = Object.keys(arrChannel).filter(z => z.toString() === "WEB");
                    if (arr.length > 0){
                        let channel = arr[0].toString();
                        num = arrChannel[channel];
                    }
                    setMaxNumTicket(num);
                }
            }
        }

        window.addEventListener('popstate', (event) => {
            if (typeof(props.values.wsRef) !== 'undefined' && props.values.wsRef !== null){
                if (typeof(props.values.wsRef.current) !== 'undefined' && props.values.wsRef.current !== null){
                    props.values.wsRef.current.close();
                }
            }
        });

    }, []);

    const getItems = (token, id) => {
        setErr("");
        setLoadItems(false);
        DataManager.getItems(token, id).then((result) => {
            if(typeof (result) !== 'undefined' && result !== null){
                console.log("items: ",result['items'].results)
                //if (typeof (result['items']) !== 'undefined' && result['items'] !== null && result['items'].length > 0) {
                if (typeof (result['items']) !== 'undefined' && result['items'] !== null) {

                    let arr = result['items'].results;
                    let arrItems = [];
                    setLoadItems(true);
                    for (let i=0; i < arr.length; i++) {
                        let obj = arr[i];
                        for (let j=0; j < obj.items.length; j++) {
                            let objItem = obj.items[j];
                            if (typeof (objItem.salesChannels) !== 'undefined'  && objItem.salesChannels !== null && objItem.salesChannels.length > 0) {
                                let arr = objItem.salesChannels.find(s => s.toString() === "WEB");
                                if (typeof (arr) !== 'undefined' && arr !== null) {
                                    arrItems.push(objItem)
                                }
                            }
                        }
                    }
                    if(typeof (props.dataReply) !== 'undefined' && props.dataReply !== null){
                        if(typeof (props.dataReply.location) !== 'undefined' && props.dataReply.location !== null){
                            if(typeof (props.dataReply.location.seatingOrders) !== 'undefined' && props.dataReply.location.seatingOrders !== null && props.dataReply.location.seatingOrders.length > 0){
                                for (let z=0; z < arrItems.length; z++) {
                                    let objItem = arrItems[z];
                                    let arrType = props.dataReply.location.seatingOrders.filter(z => z.code.toString() === objItem.seatingOrder.toString());
                                    if (arrType.length > 0){
                                        objItem["nameCategory"] = arrType[0].name.toString();
                                    }
                                }
                            }
                        }
                    }
                    setItems(arrItems);
                } else {
                    let strErr = t("msgErrorGeneral");
                    if (typeof (result['msgError']) !== 'undefined' && result['msgError'] !== null && result['msgError'] !== "") {
                        strErr = result['msgError'];
                    }
                    setErr(strErr);
                    setLoadItems(true);
                }
            }else{
                setErr(t("msgErrorServer"));
                setLoadItems(true);
            }
        });
    };

    const getSeatsSelection = (arr) => {
        setArrSeats([]);
        setArrSeats(arr);
    };

    const actionContinue = (e) => {
        e.preventDefault();
        if (arrSeats.length > 0){
            setTimeout(() => {
                Functions.backToTop();
            }, 100);
            let arrUsers = [];
            if (typeof (props.values.arrUsers) !== 'undefined' && props.values.arrUsers !== null && props.values.arrUsers.length > 0){
                arrUsers = props.values.arrUsers;
            }
            if (typeof (props.values.wsRef) !== 'undefined' && props.values.wsRef !== null){
                wsRef.current = props.values.wsRef;
            }
            props.nextStep(arrSeats, arrUsers, wsRef);
        }else{
            Functions.alertMsg(t("msgErrorSelectSeat"));
        }
    };

    return(
        <div>
            <>
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    closeOnClick
                    newestOnTop={true}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    style={{ "zIndex":1000000}}
                >
                </ToastContainer>
            </>
            <fieldset>
                <div className="page-content bg-white">
                    <Container className="container-map">
                        <Row>
                            <Col xl={2}>
                                <WidgetEvent {...props} />
                            </Col>
                            <Col xl={10}>
                                {
                                    items.length > 0
                                        ? (
                                            <SeatMap {...props}
                                                     items={items}
                                                     seatsSelection={getSeatsSelection}
                                                     maxNumTicket={maxNumTicket}
                                            />
                                        ) : !loadItems
                                            ? (
                                                <div>
                                                    <Spinner as="span"
                                                             size="sm"
                                                             role="status"
                                                             aria-hidden="true"
                                                             animation="border" className="d-flex align-items-center">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </Spinner>
                                                </div>
                                            ) : loadItems===true && items.length === 0 ?  (<div>
                                                <img src={unavailable} width="30" className="margin-left-soldout" alt={t("soldout_image")}/>{t("not_sellable")}
                                            </div>)
                                            
                                            : err !== ""
                                                ? (
                                                   err
                                                )
                                                : null
                                }
                            </Col>
                        </Row>
                    </Container>
                    {err === t("not_sellable") ? null : <ButtonWizard btnClicked={actionContinue} text={t("next")}/> }
                </div>
            </fieldset>
        </div>

    );

}

export default withRouter(SelectMap);
