import React, { Component } from "react";
import {t} from "i18next";

//pages wizard
import ProgressbarSingleEvent from "../pages/wizard/ProgressbarSingleEvent";
import Success from "../pages/wizard/Success";
import SelectMap from "../pages/wizard/SelectMap";
import Seo from "../elements/seo";
import SelectTicket from "../pages/wizard/SelectTicket";
import SelectUser from "../pages/wizard/SelectUser";
import SelectPayment from "../pages/wizard/SelectPayment";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";
import Utils from "../Utils";
import Functions from "../Functions";
import GeneralModal from "../elements/GeneralModal";
import {removeStorageMap} from "../LocalStorage/storage";

export default class Form extends Component {

    constructor(props) {

        super(props);
        this.state = {
            step: 1,
            dateTimer: '',
            typeEvent: typeof(this.props.location.state.reply) !== 'undefined' && this.props.location.state.reply !== null ?  this.props.location.state.reply.hasMap : false,
            maxItemsForOrder: 0,
            reservationTime: 0,
            totalAmount: 0,
            totalSeat: 0,
            paymentMethod: '',
            ticketsSelected: [],
            arrUsers: [],
            arrUsersDuplicate: [],
            arrSeats: [],
            itemsEventSelected: [],
            countdownMinutes: 0,
            countdownSeconds: 0,
            countDownFinish: true,
            showModal: false,
            msgModal: false,
            typeTicket: typeof(this.props.location.state) === 'undefined' && this.props.location.state === null ? [] : this.props.location.state.typeTicket,
            reply: this.props.location.state.reply,
            event: this.props.location.state.event,
            organizer: this.props.location.state.organizer,
            intervalRef: null,
            wsRef : null
        };

    }

    componentDidMount() {

        const urlWizard = window.location.href;
        const checkUrlWizard = urlWizard.includes("checkout");
        if (checkUrlWizard) {
            this.setState({
                step: 5
            });
        }else{
            this.countdownTimer();
        }

    }

    componentWillUnmount(){
        if (typeof(this.state.wsRef) !== 'undefined' && this.state.wsRef !== null){
            if (typeof(this.state.wsRef.current) !== 'undefined' && this.state.wsRef.current !== null){
                this.state.wsRef.current.close();
            }else{
                this.state.wsRef.close();
            }
        }
        removeStorageMap();
    }

    countdownTimer = () => {

        let dateAndhourCurrent = new Date();
        dateAndhourCurrent.setMinutes(dateAndhourCurrent.getMinutes() + 15);
        this.setState(
            {
                dateTimer: dateAndhourCurrent
            }
        );

        const timeInterval= setInterval(() => {
            const countdownDateTime = new Date(dateAndhourCurrent).getTime();
            const currentTime = new Date().getTime();
            const remainingDayTime = countdownDateTime - currentTime;
            const totalMinutes = Math.floor((remainingDayTime % (1000 * 60 * 60)) / (1000 * 60));
            const totalSeconds = Math.floor((remainingDayTime % (1000 * 60)) / 1000);

            this.setState(
                {
                    countdownMinutes: totalMinutes,
                    countdownSeconds: totalSeconds
                }
            );

            if (remainingDayTime < 0) {
                setTimeout(() => {
                    this.showModalComponent(true, t("msgErrorTimerExpired"));
                }, 300);
                setTimeout( () => {
                    this.props.history.goBack();
                    this.showModalComponent(false, "");
                }, 3500);
                clearInterval(timeInterval);
                this.resetTimer();
            }

        }, 1000);

        this.setState(
            {
                intervalRef: timeInterval
            }
        );

    };

    resetTimer = () => {
        clearInterval(this.state.intervalRef);
        this.setState(
            {
                countDownFinish: false
            }
        );
    };

    showModalComponent = (value, msg) => {
        this.setState(
            {
                showModal: value,
                msgModal: msg
            }
        );
    };

    setUsersDuplicate = (arrUsers) => {
        this.setState({
            arrUsersDuplicate: arrUsers,
        });
    };

    nextStep = (arrSeats, arrUsers, wsRef) => {
        if(this.state.countDownFinish !== false) {
            const { step } = this.state;
            this.setState({
                arrSeats: arrSeats,
                arrUsers: arrUsers,
                wsRef: wsRef,
                step: step + 1
            });
        }else{
            Functions.alertMsg(t("MsgTimerExpired"));
        }

    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    setArrUser = (arrUsers) => {
        if (typeof(arrUsers) !== 'undefined' && arrUsers !== null && arrUsers.length > 0){
            this.setState({
                arrUsers: arrUsers,
            });
        }
    };

    setWSRef = (wsRef) => {
        this.setState({
            wsRef: wsRef
        });
    };

    renderMap(values){
        if(!values.typeEvent){
            return <SelectTicket
                {...this.props}
                nextStep={this.nextStep}
                values={values}
                resetTimer={this.resetTimer}
                showModalComponent={this.showModalComponent}
                dataReply={this.state.reply}
                event={this.state.event}
                organizer={this.state.organizer}
            />
        }else{
            return <SelectMap
                {...this.props}
                setWSRef={this.setWSRef}
                nextStep={this.nextStep}
                setArrUser={this.setArrUser}
                values={values}
                resetTimer={this.resetTimer}
                showModalComponent={this.showModalComponent}
                dataReply={this.state.reply}
                event={this.state.event}
                organizer={this.state.organizer}
            />
        }
    }

    render() {

        const { step } = this.state;
        const {ticketsSelected, arrUsers,
            paymentMethod, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, typeEvent, arrCartPositions, countdownMinutes,
            countdownSeconds, countDownFinish, propsSeo, typeTicket, arrUsersDuplicate, arrSeats, wsRef } = this.state;

        const values = { ticketsSelected, arrUsers,
            paymentMethod, totalAmount, totalSeat,
            maxItemsForOrder, reservationTime, dateTimer, typeEvent, arrCartPositions, countdownMinutes,
            countdownSeconds, countDownFinish, propsSeo, typeTicket, arrUsersDuplicate, arrSeats, wsRef };

        let dict = {};
        dict["title"] = Utils.NAME_CUSTOMER + " | " + t("ticket_purchase") + " " + this.props.location.state.event.title.toString();
        dict["description"] = Utils.NAME_CUSTOMER + " | " + t("ticket_purchase") + " " + this.props.location.state.event.title.toString();

        return (
            <>
                <Seo propsSeo={dict} />
                <Header />
                {this.state.showModal ? <GeneralModal msgModal={this.state.msgModal} /> : null}
                <section className="section-area section-sp1 blog-area">
                    
                    <form id="msform">
                        {}
                        <ProgressbarSingleEvent step={step} />
                        <div className="App">
                            <div className="btn-group my-3">
                                <>
                                    <div className={Functions.renderClassTimer(this.state.countdownMinutes, this.state.countdownSeconds)}>{this.state.countdownMinutes}&nbsp;minuti&nbsp;:&nbsp;{this.state.countdownSeconds}&nbsp;secondi</div>
                                </>
                            </div>
                        </div>
                        {}
                        {
                            {
                                1: (
                                    this.renderMap(values) 
                                ),
                                2: (
                                    <SelectUser
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        setArrUser={this.setArrUser}
                                        values={values}
                                        resetTimer={this.resetTimer}
                                        showModalComponent={this.showModalComponent}
                                        dataReply={this.state.reply}
                                        event={this.state.event}
                                        organizer={this.state.organizer}
                                    />
                                ),
                                3: (
                                    <SelectPayment
                                        nextStep={this.nextStep}
                                        prevStep={this.prevStep}
                                        values={values}
                                        resetTimer={this.resetTimer}
                                        showModalComponent={this.showModalComponent}
                                        dataReply={this.state.reply}
                                        event={this.state.event}
                                        organizer={this.state.organizer}
                                    />
                                ),
                                4: <Success values={values}/>,
                            }[step]
                        }
                    </form>
                </section>
                <Footer />
            </>
        );
    }
}
